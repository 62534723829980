import * as React from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { GridContainer } from '@/components/Grid'

import {
  ActionWrapper,
  CardButton,
  CardContent,
  CardGalleryContent,
  CardGalleryGroup,
  CardGalleryWrapper,
  CardLink,
  CardTitle,
  CardWrapper,
  Container,
  FilterWrapper,
  ImageArrow,
  LabelIconWrapper,
  Title,
  ViewerWrapper,
  ViewVideo,
  Wrapper
} from './style'
import { Image, ImageWrapper } from '@/components/Block/Sliders/MediaSlider/MediaCard/style'

import resolveAssetURL from '@/utils/resolveAssetURL'
import FiltersVertical from '@/components/FiltersVertical'
import Button from '@/components/Button'
import { Actions } from '@/components/Block/Text/style'
import { CloseButton, Viewer } from '@/components/ImageTextPortrait/style'
import FeaturedArticleBlock from '@/components/FeaturedArticleBlock'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { marketCodeManager } from '@/recoil/marketCode'
import { journalVideoOpen, journalVideoUrl } from '@/recoil/journal'
import { ScrollReveal } from '@/utils/ScrollReveal'
import { ScrollRevealItem } from '@/utils/ScrollRevealItem'
import { truncate } from '@/utils/formatText'

import playArrow from '@/images/play-arrow.svg'
import playButton from '@/images/play-button.svg'
import closeButton from '@/images/close-button.svg'

const POSTS_BEFORE_FEATURED = 12

const CardGallery = ({
  title = '',
  items,
  filters = [],
  action,
  featureItems
}) => {
  const marketCode = useRecoilValue(marketCodeManager)
  const videoOpen = useRecoilValue(journalVideoOpen)
  const videoUrl = useRecoilValue(journalVideoUrl)
  const setVideoOpen = useSetRecoilState(journalVideoOpen)
  const setVideoUrl = useSetRecoilState(journalVideoUrl)
  const [data, setData] = useState(items)
  const [hasBlock, setHasBlock] = useState(false)
  const [displayCount, setDisplayCount] = useState(2 * POSTS_BEFORE_FEATURED)
  const [isOpen, setIsOpen] = useState(false)

  const loadMore = () => {
    setDisplayCount(data.length)
  }

  const handleData = (activeFilters = [], sortBy) => {
    let initialData = [...items]
    let hasBlock = true

    if (activeFilters?.length) {
      initialData = initialData.filter((item) => activeFilters.some(c => item.catId.includes(c)))
      hasBlock = false
    }
    setHasBlock(hasBlock)

    if (sortBy === 'latest') {
      initialData.sort((a, b) => new Date(b.date) - new Date(a.date))
    } else if (sortBy === 'oldest') {
      initialData.sort((a, b) => new Date(a.date) - new Date(b.date))
    } else if (sortBy === 'a-to-z') {
      initialData.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
    } else if (sortBy === 'z-to-a') {
      initialData.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase())).reverse()
    }

    setData(initialData)
    mainRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    })
  }

  const mainRef = useRef(null)
  const wrapperRef = useRef(null)
  const filterWrapperRef = useRef(null)

  useEffect(() => {

  }, [data])

  useEffect(() => {
    const wrapper = wrapperRef.current

    const handleScroll = () => {
      window.requestAnimationFrame(() => {
        const wrapperRect = wrapper.getBoundingClientRect()
        const offset = Math.min(Math.max(-(wrapperRect.top - 100), 0), (wrapperRect.height - 418))
        filterWrapperRef.current.style.setProperty('--transform-Y', `${offset}px`)
      })
    }

    document.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleScroll)

    return () => {
      wrapper.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleScroll)
    }
  }, [])

  const getFeatureBlock = (index) => {
    const content = featureItems[index]
    const {
      title,
      category,
      image,
      url,
      video
    } = content
    const isVideo = !!video
    const code = video?.split('/')[3]

    return (
      <FeaturedArticleBlock
        key={ `highlight-${index}` }
        subtitleTag='span'
        subtitle={ `Featured ${isVideo ? 'Video' : 'Article'}` }
        titleTag='h2'
        title={ title }
        text={ category }
        image={ image }
        isVideo={ isVideo }
        actions={ [
          isVideo
            ? (
              <LabelIconWrapper onClick={
                (e) => {
                  e.preventDefault()
                  setVideoUrl(`https://player.vimeo.com/video/${code}`)
                  setVideoOpen(true)
                }
              }
              >
                <Button
                  key={ 1 } to='#' onClick={
                  (e) => {
                    e.preventDefault()
                  }
                }
                >
                  WATCH VIDEO
                </Button>
                <ImageArrow loading='lazy' src={ playArrow } alt='play icon' />
              </LabelIconWrapper>
              )
            : <Button key={ 1 } to={ url }>LEARN MORE</Button>
        ] }
      />
    )
  }

  const getArticlesCards = () => {
    const cards = data.slice(0, displayCount).map((item, index, actualDataArray) => {
      const code = item.video?.split('/')[3]
      const cardWrapper = (
        <CardWrapper key={ `card-${index}` }>
          {item.video
            ? (
              <CardContent onClick={
              () => {
                setVideoUrl(`https://player.vimeo.com/video/${code}`)
                setVideoOpen(true)
              }
            }
              >
                <ImageWrapper data-url={ resolveAssetURL(item.image) } data-alt={ item?.imageAlt }>
                  <Image loading='lazy' src={ resolveAssetURL(item.image) } alt={ item?.imageAlt } />
                  <CardButton
                    onClick={
                    () => {
                      setVideoUrl(`https://player.vimeo.com/video/${code}`)
                      setVideoOpen(true)
                    }
                  }
                  >
                    <Image src={ playButton } alt='open icon' />
                  </CardButton>
                </ImageWrapper>
                <CardTitle>{truncate(item.title, 75)}</CardTitle>
              </CardContent>
              )
            : (
              <CardLink href={ `/${marketCode}${item.url}` } title={ item.title }>
                <ImageWrapper>
                  <Image loading='lazy' src={ resolveAssetURL(item.image) } alt={ item?.imageAlt } />
                </ImageWrapper>
                <CardTitle>{truncate(item.title, 75)}</CardTitle>
              </CardLink>
              )}
        </CardWrapper>
      )
      const articleContentItem = hasBlock
        ? (
          <ScrollRevealItem key={ `card-${index}` }>
            {cardWrapper}
          </ScrollRevealItem>
          )
        : cardWrapper
      const articleContent = [articleContentItem]

      const featureIndex = Math.ceil(index / POSTS_BEFORE_FEATURED)
      const featuresBlockLeft = featureItems.length >= featureIndex
      const enoughArticles = (POSTS_BEFORE_FEATURED * featureIndex) < actualDataArray.length
      const featureBlockCanShow = (index + 1) % POSTS_BEFORE_FEATURED === 0

      const showFeatureBlock = featureBlockCanShow && hasBlock && featuresBlockLeft && enoughArticles

      if (showFeatureBlock) {
        articleContent.push(getFeatureBlock(Math.abs(featureIndex - 1)))
      }
      return articleContent
    }).reduce((acc, curr) => [...acc, ...curr], []).reduce(
      (acc, curr, index) => {
        const { key } = curr
        const s = key.split('-')
        const bundle = s[0]
        const realIndex = s[1]

        if (bundle === 'card') {
          const group = Math.ceil((parseInt(realIndex) + 1) / POSTS_BEFORE_FEATURED)

          if (!acc[`${bundle}-${group}`]) {
            acc[`${bundle}-${group}`] = []
          }

          acc[`${bundle}-${group}`].push(curr)
        }

        if (bundle === 'highlight') {
          if (!acc[`${key}`]) {
            acc[`${key}`] = []
          }

          acc[`${key}`].push(curr)
        }

        return acc
      },
      {}
    )

    return Object.entries(cards)
  }

  const itemsMemo = useMemo(() => {
    return getArticlesCards().map((item, i) => {
      const key = item[0]
      const bundle = key.split('-')[0]
      const childrensArr = item[1]
      switch (bundle) {
        case 'card':
          return (
            <CardGalleryGroup key={ `gallerygroup-${i}` }>
              <ScrollReveal>
                {childrensArr}
              </ScrollReveal>
            </CardGalleryGroup>
          )
        case 'highlight':
        default:
          return childrensArr[0]
      }
    })
  }, [getArticlesCards])
  return (
    <Container ref={ mainRef }>
      <GridContainer>
        {!!title && <Title>{title}</Title>}
        <Wrapper ref={ wrapperRef }>
          {
            !!filters.length && (
              <FilterWrapper ref={ filterWrapperRef }>
                <FiltersVertical
                  filters={ filters }
                  onChange={ handleData }
                />
              </FilterWrapper>
            )
          }
          <CardGalleryWrapper id='card__gallery'>
            <CardGalleryContent>
              {itemsMemo}
            </CardGalleryContent>
            {displayCount < data.length && (
              <ActionWrapper>
                <Actions className='one' onClick={ loadMore }>{action}</Actions>
              </ActionWrapper>
            )}
          </CardGalleryWrapper>
        </Wrapper>

        <Viewer isOpen={ isOpen }>
          <ViewerWrapper>
            <ViewVideo
              src={ `${videoUrl}?autoplay=1` }
              allow='autoplay'
              frameborder='0'
              onLoad={ (e) => {
                setIsOpen(videoOpen)
              } }
            />
          </ViewerWrapper>
          <CloseButton
            onClick={
              () => {
                setVideoUrl(null)
                setVideoOpen(false)
                setIsOpen(false)
              }
            }
          >
            <Image src={ closeButton } alt='close icon' />
          </CloseButton>
        </Viewer>
      </GridContainer>
    </Container>
  )
}

CardGallery.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    ref: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    video: PropTypes.string
  })),
  filters: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    catId: PropTypes.string
  })),
  action: PropTypes.node,
  featureItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    category: PropTypes.string,
    name: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    ref: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    video: PropTypes.string
  }))
}

export default CardGallery
