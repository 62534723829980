import * as React from 'react'
import PropTypes from 'prop-types'
import { ScrollRevealItemStyled } from '@/utils/ScrollRevealItem/style'
import { useEffect, useRef } from 'react'

export const ScrollRevealItem = ({ children, registerRef, isRevealed }) => {
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      registerRef(ref.current)
    }
  }, [ref, registerRef])

  return (
    <ScrollRevealItemStyled ref={ ref } isRevealed={ !!isRevealed }>
      {children}
    </ScrollRevealItemStyled>
  )
}
ScrollRevealItem.propTypes = {
  children: PropTypes.node.isRequired,
  registerRef: PropTypes.func,
  isRevealed: PropTypes.bool
}
