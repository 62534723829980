import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectFade, Pagination } from 'swiper'

import {
  Content,
  ContentButton,
  ContentCategories,
  ContentCategory,
  ContentDate,
  ContentLink,
  ContentTitle,
  Wrapper
} from './style'
import { Container, Overlay } from '@/components/HomeHero/Item/style'
import resolveAssetURL from '@/utils/resolveAssetURL'
import { CloseButton, Viewer } from '@/components/ImageTextPortrait/style'
import { ViewerWrapper, ViewVideo } from '@/components/CardGallery/style'
import { Image } from '@/components/Block/Sliders/MediaSlider/MediaCard/style'
import closeButton from '@/images/close-button.svg'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { journalVideoOpen, journalVideoUrl } from '@/recoil/journal'

const HeroJournal = ({ slides }) => {
  const videoOpen = useRecoilValue(journalVideoOpen)
  const videoUrl = useRecoilValue(journalVideoUrl)
  const setVideoOpen = useSetRecoilState(journalVideoOpen)
  const setVideoUrl = useSetRecoilState(journalVideoUrl)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Wrapper>
      <Swiper
        modules={ [Pagination, Autoplay, EffectFade] }
        pagination={ { clickable: true } }
        loop
        shortSwipes={ false }
        longSwipesRatio={ 0.2 }
        autoplay={ { disableOnInteraction: false, delay: 7000 } }
        effect='fade'
        watchSlidesProgress
      >
        {
          slides.slice(0, 3).map((slide, i) => {
            const {
              video,
              image,
              category,
              title,
              ctaTo
            } = slide
            const date = moment(slide.date).format('LL')
            const code = video?.split('/')[3]
            return (
              <SwiperSlide key={ `slide-${i}` }>
                <Container background={ resolveAssetURL(image) }>
                  <Overlay>
                    <Content>
                      <ContentCategories>
                        <ContentCategory>{category}</ContentCategory>
                      </ContentCategories>
                      <ContentTitle>{title}</ContentTitle>
                      <ContentDate>{date}</ContentDate>
                      {!video
                        ? (
                          <ContentLink to={ ctaTo }>Learn More</ContentLink>
                          )
                        : (
                          <ContentButton
                            onClick={ (e) => {
                              e.preventDefault()
                              setVideoUrl(`https://player.vimeo.com/video/${code}`)
                              setVideoOpen(true)
                            } }
                          >
                            Watch Video
                          </ContentButton>
                          )}
                    </Content>
                  </Overlay>
                </Container>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
      <Viewer isOpen={ isOpen }>
        <ViewerWrapper>
          <ViewVideo
            src={ `${videoUrl}?autoplay=1` }
            allow='autoplay'
            frameborder='0'
            onLoad={ (e) => setIsOpen(videoOpen) }
          />
        </ViewerWrapper>
        <CloseButton
          onClick={
            () => {
              setVideoUrl(null)
              setVideoOpen(false)
              setIsOpen(false)
            }
          }
        >
          <Image src={ closeButton } alt='close icon' />
        </CloseButton>
      </Viewer>
    </Wrapper>
  )
}

HeroJournal.propTypes = {
  slides: PropTypes.array.isRequired
}

export default HeroJournal
