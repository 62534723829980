import * as React from 'react'
import { useEffect, useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'

export const ScrollReveal = ({ children }) => {
  const [revealStates, setRevealStates] = useState({})
  const refs = useRef([])
  const observer = useRef(null)

  const registerRef = useCallback((ref, index) => {
    refs.current[index] = ref
  }, [])

  useEffect(() => {
    const threshold = 0.1
    const options = {
      root: null,
      rootMargin: '0px',
      threshold
    }

    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        const index = refs.current.indexOf(entry.target)
        if (index !== -1 && !revealStates[index]) {
          if (entry.isIntersecting) {
            setRevealStates((prevStates) => ({
              ...prevStates,
              [index]: true
            }))

            observer.current.unobserve(entry.target)
          }
        }
      })
    }

    observer.current = new IntersectionObserver(
      handleIntersect,
      options
    )

    refs.current.forEach((ref) => {
      if (ref) observer.current.observe(ref)
    })

    return () => {
      refs.current.forEach((ref) => {
        if (ref) observer.current.unobserve(ref)
      })
    }
  }, [children])

  const clonedChildren = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        registerRef: (ref) => registerRef(ref, index),
        isRevealed: revealStates[index] || false
      })
    }
    return child
  })

  return <>{clonedChildren}</>
}

ScrollReveal.propTypes = {
  children: PropTypes.node.isRequired
}
