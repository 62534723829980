import React, { useEffect, useState } from 'react'
import {
  CheckboxGroup,
  CheckboxInput,
  CheckboxLabel,
  Checkmark,
  Divider,
  FilterContainer,
  Select,
  SubTitle,
  Title
} from './style'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { isMobileState } from '@/recoil/layout'
import SelectFilter from '@/components/Forms/Inputs/Select'
import slugify from '@/utils/slugify'

const FiltersVertical = ({ filters, onChange }) => {
  const [isReady, setIsReady] = useState(false)
  const [sortBy, setSortBy] = useState('latest')
  const [formattedFilters, setFormattedFilters] = useState(
    filters.filter(({ label }) => label !== 'All').sort(function (x, y) {
      if (x.weight < y.weight) {
        return -1
      }
      if (x.weight > y.weight) {
        return 1
      }
      return 0
    })
  )
  const [selectedIds, setSelectedIds] = useState([])
  const isMobile = useRecoilValue(isMobileState)

  const handleSortChange = (event) => {
    setSortBy(event?.target?.value || slugify(event))
  }

  const handleFilterChange = (event) => {
    if (isMobile) {
      const selectedFilter = filters.find((filter) => filter.label === event)?.catId
      setSelectedIds(slugify(event) === 'all' ? [] : [selectedFilter])
    } else {
      const { checked, name } = event.target
      const id = filters.find((filter) => filter.label === name)?.catId
      if (checked) {
        setSelectedIds((ids) => [...ids, id])
      } else {
        setSelectedIds((ids) => ids.filter((existingId) => existingId !== id))
      }
    }
  }

  useEffect(() => {
    getFiltersSortToSearchParams()
  }, [])

  useEffect(() => {
    onChange(selectedIds, sortBy)
  }, [selectedIds, sortBy])

  useEffect(() => {
    if (isReady) {
      setFiltersSortToSearchParams()
    }
  })

  const getFiltersSortToSearchParams = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const filters = queryParams.get('filters')
    const sort = queryParams.get('sort')

    if (filters) {
      setSelectedIds((ids) => [...ids, ...filters.split(',')])
    }

    if (sort) {
      setSortBy(sort)
      onChange([...filters.split(',')], sort)
    }

    setIsReady(true)
  }

  const setFiltersSortToSearchParams = () => {
    const queryParams = new URLSearchParams({ filters: selectedIds, sort: sortBy })
    const url = `${window.location.origin}${window.location.pathname}?${queryParams.toString()}`

    window.history.pushState({ path: url }, '', url)
  }

  return (
    <FilterContainer id='filter__container'>
      {!isMobile
        ? (
          <>
            <Title>Sort by</Title>
            <Select value={ sortBy } onChange={ handleSortChange }>
              <option value='latest'>Latest</option>
              <option value='oldest'>Oldest</option>
              <option value='a-to-z'>A to Z</option>
              <option value='z-to-a'>Z to A</option>
            </Select>
            <Divider />
            <Title>Filter by</Title>
            <CheckboxGroup>
              <SubTitle>Interests</SubTitle>
              {
              formattedFilters.map(({ label, catId }, i) => (
                <CheckboxLabel key={ i }>
                  <CheckboxInput
                    type='checkbox'
                    id={ catId }
                    name={ label }
                    checked={ selectedIds.indexOf(catId) > -1 }
                    onChange={ handleFilterChange }
                  />
                  <Checkmark />
                  {label}
                </CheckboxLabel>
              ))
            }
            </CheckboxGroup>
          </>
          )
        : (
          <>
            <SelectFilter
              label='Sort by'
              list={ ['Latest', 'Oldest', 'A to Z', 'Z to A'] }
              onChange={ handleSortChange }
              name='sortSelect'
              withDropDownIcon
              isFilter
            />
            <SelectFilter
              label='Filter by'
              list={ filters.map(({ label }) => label) }
              onChange={ handleFilterChange }
              name='filterSelect'
              withDropDownIcon
              isFilter
            />
          </>
          )}
    </FilterContainer>
  )
}

FiltersVertical.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    catId: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['enabled', 'disabled'])
  })).isRequired,
  onChange: PropTypes.func.isRequired
}

export default FiltersVertical
