import styled from '@emotion/styled'

import { mediaMax, sectionTitle } from '@/styles/mixins'

import { StyledGridContainer } from '@/components/Grid'
import { Actions } from '@/components/Block/Text/style'
import { ButtonWrapper } from '@/components/Button/style'
import { ScrollRevealItemStyled } from '@/utils/ScrollRevealItem/style'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 0 8rem 0;

  ${StyledGridContainer} {
    position: relative;
  }
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  min-height: 60vh;

  ${mediaMax.xs} {
    flex-direction: column;
    align-items: center;
    min-height: auto;
  }
`

export const ImageArrow = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const LabelIconWrapper = styled.div`
  display: flex;
  gap: 2rem;
  cursor: pointer;
`

export const FilterWrapper = styled.div`
  --transform-Y: 0px;
  position: absolute;
  top: 0;
  left: 0;
  width: 15rem;
  height: auto;
  padding-top: 4rem;
  transform: translateY(var(--transform-Y));
  overflow-y: auto;

  ${mediaMax.xs} {
    transition: all linear 0s;
    position: relative;
    width: 100%;
    height: auto;
    padding-top: 0;
    margin-top: -0.5rem;
    overflow: inherit;
    z-index: 100;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${Actions} {
    margin-top: 0;
  }

  ${ButtonWrapper} {
    background: #F4EFE2 !important;
    border: 2px solid #F4EFE2 !important;

    div > span {
      color: ${({ theme }) => theme.colors.brown} !important;
    }
  }

  ${mediaMax.sm} {
    ${Actions} {
      width: auto;
    }
  }
`

export const Title = styled.h2`
  ${sectionTitle};
  font-size: 4.5rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.green};
  margin-bottom: 5rem;

  ${mediaMax.xs} {
    font-size: 3.2rem;
    letter-spacing: -0.02rem;
    margin-bottom: 4rem;
  }
`

export const CardGalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  column-gap: 1.8rem;
  row-gap: 4.2rem;
  width: 100%;
  transition: all 0.5s ease-out;

  &.fade-out {
    opacity: 0;
    transform: translateY(20px);
  }

  .fade-in {
    animation: fadeInAnimation 0.5s ease-in-out forwards;
  }

  @keyframes fadeInAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  ${mediaMax.xs} {
    column-gap: 0;
    row-gap: 4.4rem;
    margin-top: 3rem;
  }
`

export const CardGalleryContent = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  column-gap: 1.8rem;
  row-gap: 4.2rem;
  flex-wrap: wrap;

  ${mediaMax.xs} {
    column-gap: 0;
    row-gap: 4.4rem;
  }
`

export const CardGalleryGroup = styled.div`
  width: calc(100% - 15rem);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 2rem;
  row-gap: 4.2rem;
  flex-wrap: wrap;
  padding-left: 10rem;

  ${mediaMax.xl} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${mediaMax.md} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${mediaMax.sm} {
    grid-template-columns: repeat(1, 1fr);
  }

    // ${ScrollRevealItemStyled} {
  //  width: calc(100% / 4);
  //}

  ${mediaMax.xs} {
    width: 100%;
    column-gap: 0;
    row-gap: 4.4rem;
    flex-direction: column;

    padding-left: 0;

    ${ScrollRevealItemStyled} {
      width: 100%;
    }
  }
`

export const CardWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 100%;

  ${mediaMax.xs} {
    width: 100%;
  }
`

export const CardLink = styled.a`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-decoration: none;
`
export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-decoration: none;
  pointer-events: all;
  cursor: pointer;
`

export const CardTitle = styled.h2`
  ${sectionTitle()};
  font-size: 1.9rem;
  color: ${({ theme }) => theme.colors.greyText};
  line-height: 100%;
  margin-top: 1.6rem;

  ${mediaMax.xs} {
    font-size: 2rem;
    line-height: 1.2em;
  }
`

export const CardButton = styled.button`
  all: unset;
  position: absolute;
  bottom: 1.2rem;
  right: 1.2rem;
  width: 4.8rem;
  height: 4.8rem;
  transition: transform 0.3s ease-out;
  pointer-events: all;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    transform: scale(1.1);
  }
`

export const ViewerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 4rem 10rem;

  ${mediaMax.sm} {
    margin: auto;
    padding: 0;
  }
`

export const ViewVideo = styled.iframe`
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${mediaMax.sm} {
    object-fit: contain;
  }
`
