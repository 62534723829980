import styled from '@emotion/styled'
import { mediaMax, sectionTitle, sectionSubtitle } from '@/styles/mixins'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
`

export const ImageWrapper = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
`

export const IconWrapper = styled.div`
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 1;
`

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 2.5rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  ${mediaMax.xs}{
    padding: 1.6rem 0;
  }
`

export const Category = styled.div`
  ${sectionSubtitle()};
  color: ${({ theme }) => theme.colors.lightGreen};
  margin-bottom: 1.2rem;
`

export const Title = styled.h2`
  ${sectionTitle()};
  font-size: 2.8rem;
  color: ${({ theme }) => theme.colors.greyText};
  margin-bottom: 1.5rem;
  ${mediaMax.xs} {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
`

export const Text = styled.p`
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  color: ${({ theme }) => theme.colors.grey};
  ${mediaMax.xs} {
    font-size: 1.6rem;
  }
`
