import React from 'react'

import { Wrapper } from './style'
import CardGallery from '@/components/CardGallery'
import PropTypes from 'prop-types'
import Button from '@/components/Button'

const JournalCardBlock = ({ title = '', items, filters, featureItems }) => {
  return (
    <Wrapper>
      <CardGallery
        title={ title }
        items={ items }
        filters={ filters }
        featureItems={ featureItems }
        action={ <Button key={ 1 }>Load More</Button> }
      />
    </Wrapper>
  )
}

JournalCardBlock.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    ref: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    video: PropTypes.string
  })),
  filters: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    catId: PropTypes.string
  })),
  featureItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    category: PropTypes.string,
    name: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    ref: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    video: PropTypes.string
  }))
}

export default JournalCardBlock
