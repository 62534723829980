import styled from '@emotion/styled'

import { mediaMax } from '@/styles/mixins'

import { Title } from '@/components/HomeHero/Item/style.js'
import { ButtonWrapper as Button } from '@/components/Button/style'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 60rem;

  ${mediaMax.xs} {
    flex-direction: column-reverse;
    height: fit-content;
  }
`

export const ImageWrapper = styled.figure`
  position: relative;
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  overflow: hidden;

  ${mediaMax.xs} {
    width: 100%;
    height: 35rem;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 50%;
  height: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  padding: 0 6.2rem;
  background-color: ${({ theme }) => theme.colors.green};

  ${mediaMax.xs} {
    width: 100%;
    height: fit-content;
    padding: 4.4rem 3.2rem 6rem;
  }
`

export const ContentSubtitle = styled.span`
  display: block;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.white};
  letter-spacing: 0.2rem;
  text-transform: uppercase;

  ${mediaMax.md} {
    font-size: 1rem;
  }

  ${mediaMax.xs} {
    font-size: ${({ theme }) => theme.fontSizes.subtitle.xs};
  }
`

export const ContentTitle = styled(Title)`
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-weight: 400;
  font-size: 3.6rem;
  line-height: 1.2em;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  margin-top: 3.2rem;
  margin-bottom: 0 !important;

  ${mediaMax.md} {
    font-size: 2.8rem;
    margin-top: 3rem;
  }

  ${mediaMax.xs} {
    max-width: 100%;
    font-size: 2.4rem;
    margin-top: 2.8rem;
  }
`

export const ContentText = styled.p`
  width: 100%;
  font-size: 1.2rem;
  color: ${p => p.theme.colors.white};
  line-height: 1.2em;
  letter-spacing: 0.2rem;
  margin-top: 3.2rem;
  text-transform: uppercase;

  ${mediaMax.md} {
    font-size: 1rem;
    margin-top: 3rem;
  }

  ${mediaMax.xs} {
    margin-top: 2.8rem;
  }
`

export const ContentActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 80%;
  height: fit-content;
  margin: 12rem auto 0;
  pointer-events: all;
  cursor: pointer;

  a {
    width: fit-content;
    padding: 0 !important;
    border: none !important;

    &:hover {
      background-color: transparent !important;

      span {
        color: ${({ theme }) => theme.colors.white} !important;
      }
    }
  }

  span {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.4em;
    letter-spacing: -0.02rem;

    ${mediaMax.md} {
      font-size: 1.4rem;
    }
  }

  img {
    width: auto;
    height: 3.2rem;
    object-fit: contain;

    ${mediaMax.md} {
      height: 2.4rem;
    }
  }

  &.many {
    ${Button} {
      width: fit-content;
    }

    ${mediaMax.xs} {
      margin-bottom: 2.5rem;
    }
  }

  ${mediaMax.xs} {
    margin-top: 5.2rem;

    &.many {
      flex-direction: column;

      ${Button} {
        padding: 2rem 2.4rem;
      }
    }
  }
`

export const Container = styled.div`
  display: flex;
  width: calc(100% - 25rem);
  margin: 4rem 0;
  overflow: hidden;

  ${mediaMax.md} {
    padding: 0;
  }

  ${mediaMax.xs} {
    margin: 0;
    padding: 0;
    width: 100%;
  }
`
